import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
    return (
        <>
        <footer>
            <section className="footer-holder">
                <div className="footer-actual">

                    <Link className="footer-logo">
                        Ayush Sharma
                    </Link>

                    <div className="footer-links">
                        <ul className="gen-links">
                            <Link to='/projects' className='gen-links-single'>
                                <li>
                                    PROJECTS
                                </li>
                            </Link>
                            <Link to='/about' className='gen-links-single'>
                                <li >
                                    ABOUT
                                </li>
                            </Link>
                            <Link to='/contact' className='gen-links-single'>
                                <li>
                                    CONTACT
                                </li>
                            </Link>
                        </ul>

                        <ul className="socials">
                            <li><a href='/'><i className="fa-brands fa-twitter fa-2xl"></i></a></li>
                            <li><a href="/"><i className="fa-brands fa-instagram fa-2xl"></i></a></li>
                            <li><a href="/"><i className="fa-brands fa-linkedin fa-2xl"></i></a></li>
                        </ul>

                    </div>
                </div>
            </section>

            <p>© 2023 All rights reserved.</p>

        </footer>
        </>
    )
}

export default Footer
