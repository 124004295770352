import React from 'react'
import './Projects.css'

const Projects = () => {
  return (
    <>

      <section className="specials">
        <h1 id="title">Experience</h1>


        <div className="container">
          <div className="left">
            <div className="img-holder">
              <img src="images/idronam.png" alt="..." />
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h3>Idronam - Internet based Drone Ground Control System</h3>
              <p>Quick lightweight shortnotes app that I built while learning react and localStorage concepts.</p>
              {/* <button>Learn More</button> */}
            </div>
          </div>
        </div>



        <div className="container">
          <div className="left">
            <div className="img-holder">
              <img src="images/aicyte.png" alt="..." />
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h3>AICYTE - UIET, Chandigarh</h3>
              <p>Quick lightweight shortnotes app that I built while learning react and localStorage concepts.</p>
              {/* <button>Learn More</button> */}
            </div>
          </div>
        </div>



      </section>

      <section className="specials">
        <h1 id="title">Personal Projects</h1>


        <div className="container">
          <div className="left">
            <div className="img-holder">
              <img src="images/plotmuse_dashboard.png" alt="..." />
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h3>Plotmsue.com</h3>
              <p>A powerfull scriptwrting app that removes all the technical clutter so writers can focus on the story. </p>
              {/* <button>Learn More</button> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="left">
            <div className="img-holder">
              <img src="images/Project2.png" alt="..." />
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h3>Osprey+ </h3>
              <p>WISHLIST and SAVE your favorite movies from a vast database (by TMDB) of upcoming and available movies. This app features a beautiful and inuitive interface.</p>
              {/* <button>Learn More </button> */}
            </div>
          </div>
        </div>


        <div className="container">
          <div className="left">
            <div className="img-holder">
              <img src="images/jotte.png" alt="..." />
            </div>
          </div>
          <div className="right">
            <div className="text-container">
            <h3>Jotte.</h3>
              <p>Quick lightweight shortnotes app that I built while learning react and localStorage concepts. It comes with both light and dark modes.</p>
              {/* <button>Learn More </button> */}
            </div>
          </div>
        </div>





      </section>

    </>
  )
}

export default Projects
