// rafc
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
// import MenuBars from './images/icons8-menu.svg' // Tell Webpack this JS file uses this image
// import MenuClose from './images/icons8-close (1).svg'
// import Switch from './Switch'

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <>
            <nav>
                <div>
                    <Link to="/" className='nav-logo' onClick={() => setMenuOpen(false)}>
                        @Ayush Sharma
                    </Link>

                    <ul className='nav-bar'>
                        <Link to='/projects' className='nav-links'>
                            <li>
                                Project
                            </li>
                        </Link>
                        <Link to='/about' className='nav-links'>
                            <li >
                                About
                            </li>
                        </Link>
                        <Link to='/contact' className='nav-links'>
                            <li>
                                Contact
                            </li>
                        </Link>
                        
                    </ul>

                    <div className='menu-btn' onClick={() => setMenuOpen(!menuOpen)}>
                        <span className={menuOpen ? 'upper-menu-bar-close' : 'upper-menu-bar'}></span>
                        <span className={menuOpen ? 'lower-menu-bar-close' : 'lower-menu-bar'}></span>
                    </div>
                    <div className="mobile-menu" style={{ height: `${menuOpen ? 'calc(1000vh - 100px)' : 0}` }}>
                        <ul className='nav-bar' >
                            <Link to='/projects' className='nav-links' onClick={() => setMenuOpen(false)}>
                                <li>
                                    Projects
                                </li>
                            </Link>
                            <Link to='/about' className='nav-links' onClick={() => setMenuOpen(false)}>
                                {/* onclick function closes the mobile-nav after routing to the destination */}
                                <li >
                                    About
                                </li>
                            </Link>
                            <Link to='/contact' className='nav-links' onClick={() => setMenuOpen(false)}>
                                <li>
                                    Contact
                                </li>
                            </Link>
                            {/* <li className='nav-links'>
                            <Switch />
                        </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
