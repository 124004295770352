import React from 'react'
import Hero from './Hero'
import Project from './More'
import Socialsholder from './Socialsholder'
import Projects from './Projects'
import Footer from './Footer'


const Home = () => {
  return (
    <>
      <Hero/>
      <Socialsholder/>
      <Project/>
      <Projects/>
      <Footer />
    </>
  )
}

export default Home
