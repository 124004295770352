import React from 'react'
import './More.css'

const Project = () => {
  return (
    <section className='projects'>
      <div className='image-section'>
        <img src="./images/ayush.jpg" alt="..." />
        {/* <ul>
          <li>REACT js</li>
          <li>NODE js</li>
          <li>JAVASCRIPT</li>
        </ul> */}
      </div>
      <div>
        <h3>I'm a software engineer specializing in building (and occasionally designing)
          exceptional digital experiences.
          Currently, I'm focused on building accessible, higly-productive human-centered products.</h3>
        <div className="stack">
          <ul>
            <li>REACT js</li>
            <li>NODE js</li>
            <li>JAVASCRIPT</li>
            <li>CSS</li>
            <li>SASS</li>
            <li>FIGMA</li>
            <li>GIT</li>
            <li>Ai</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Project
