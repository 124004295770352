import React, { useState, useEffect } from 'react';
import './Hero.css';
import { motion } from 'framer-motion';

const Hero = () => {
    const arr = [
        'Hello,',            // English
        'Привет,',           // Russian
        'Hallo,',            // German
        'Hola,',             // Spanish
        'Olá,',              // Portuguese
        'नमस्ते,',
        'こんにちは,',         // Japanese
        'Bonjour,',          // French
        'Ciao,',             // Italian
        '你好,',               // Chinese (Mandarin)
        '안녕하세요,'         // Korean
    ]

    const [word, setWord] = useState(arr[Math.floor(Math.random() * 10)]);
    useEffect(() => {
        let i = Math.floor(Math.random() * 10);
        const interval = setInterval(() => {
            setWord(arr[i % arr.length]);
            i++;
        }, 1500);

        return () => clearInterval(interval);
    });

    const containerVariant = {
        hidden: { opacity: 1, y: 0 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0,
                duration: 0,
                when: 'beforeChildren',
                staggerChildren: 0.14
            }
        }
    }

    const itemVarient = {
        hidden: { opacity: 0, y: 100 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.54,
                type: 'spring',
                damping: 10,
            }
        }
    }

    const imgContainerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delay: 1,
                duration: 1
            }
        }
    }

    return (
        <section className='hero-area'>
            {/* <img className="images" src="./images/drone.png" alt="..." />
            <img className="images" src="./images/delivery_drone.png" alt="..." />
            <img className="images" src="./images/Vector.png" alt="..." /> */}
            <div className="hero-sub-area">
                <div className="part1">
                    <motion.div className="text-slot"
                        variants={containerVariant}
                        initial="hidden"
                        animate="visible">

                        <motion.h2
                            variants={itemVarient}//weird behavior
                        > <img src="https://img.icons8.com/emoji/48/waving-hand-emoji.png" alt="waving-hand-emoji" />{word}</motion.h2>
                        <motion.h1
                            variants={itemVarient}

                        >I'm <strong>Ayush Sharma</strong></motion.h1>
                        <motion.h3
                            variants={itemVarient}

                        >
                            {/* <img src="https://img.icons8.com/3d-fluency/94/laptop.png" alt="laptop" /> */}
                            Full-stack developer</motion.h3>
                        <motion.h3
                            variants={itemVarient}
                        >
                            {/* <img src="https://img.icons8.com/3d-fluency/94/design.png" alt="design" /> */}
                            UI/UX, and Graphic designer.</motion.h3>
                        <motion.a
                            variants={itemVarient}//weird behavior

                            href="/">Projects</motion.a>
                    </motion.div>

                </div>
                <div className="part2">
                    {/* Other content */}
                    <motion.div variants={imgContainerVariants} initial="hidden" animate="visible">
                        <img src="./images/ayush_memoji.png" alt="" />
                    </motion.div>
                </div>
            </div>
        </section >
    );
}

export default Hero;
