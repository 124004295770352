import React from 'react'
import './Socialsholder.css'

const Socialsholder = () => {
    return (
        <>
            <div className="socials-holder">
                <ul>
                    <li><a href='/'><i className="fa-brands fa-twitter fa-xl" ></i></a></li>
                    <li><a href="/"><i className="fa-brands fa-instagram fa-xl" ></i></a></li>
                    <li><a href="/"><i className="fa-brands fa-linkedin fa-xl" ></i></a></li>
                </ul>
            </div>
        </>
    )
}

export default Socialsholder
